import Router from './Router'

const App = () => {
    return (
        <>
            <Router />
        </>
    )
}

export default App
